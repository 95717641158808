<template>
  <div class="sales-category-setting">
    <PageTitle
      title="點數卡設定"
      btn="新增"
      @btnClick="$router.push({name: 'CreatePointCard'})"
    />
    <FiltersContainer>
      <BaseElInput
        v-model="search.name"
        testName="search_name"
        clearable
        placeholder="搜尋點數卡名稱"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh" />
      </BaseElInput>
    </FiltersContainer>

    <section>
      <BaseTable v-loading="loading.table" :data="displayData" empty-text="暫無數據" testName="pointCardSetting">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="name" label="名稱" align="center" />
        <BaseElTableColumn prop="totalAmount" label="點數數量" align="center" />
        <BaseElTableColumn prop="exp" label="有效期限" align="center" />
        <BaseElTableColumn prop="order" label="排序" align="center" />
        <BaseElTableColumn label="操作" fixed="right" width="150" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              :testName="`pointCard_${scope.row.name}`"
              :hideCopy="false"
              @edit="$router.push({
                name: 'CreatePointCard',
                params: { pointCardId: scope.row.id }
              })"
              @copy="$router.push({
                name: 'CreatePointCard',
                params: { pointCardId: scope.row.id },
                query: { copy: true },
              })"
              @delete="deleteDialog=true, selectRow=scope.row"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableDataCount"
        @pageChange="refresh"
      />
    </section>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      btnString="刪除"
      @close="deleteDialog = false"
      @delete="deletePointCard(), deleteDialog = false"
    />
  </div>
</template>

<script>
import { defineComponent, onActivated, onMounted, ref, reactive, computed } from 'vue'
import TableEditBtnGroup from '@/components/Button/TableEditBtnGroup.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import EmptyBlock from '@/components/EmptyBlock.vue'
import ThumbnailDefault from '@/assets/classTicket/thumbnail_default.svg'
import { get, map } from 'lodash'
import { useTable } from '@/use/table'
import { useRoute } from 'vue-router/composables'
import {
  GetPointCard,
  GetPointCardCount,
  DeletePointCard,
} from '@/api/pointCard'
import notifyMessage from '@/config/notifyMessage'
import { imgSrc } from '@/utils/helper'

export default defineComponent({
  name: 'PointCardSetting',
  components: {
    DeleteDialog,
    EmptyBlock,
    TableEditBtnGroup,
  },
  setup () {
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      fetchData,
      fetchDataCount,
      shopId,
      dateFormat,
    } = useTable()
    const route = useRoute()
    const search = reactive({
      name: '',
    })
    const deleteDialog = ref(false)
    const selectRow = ref(null)
    const displayData = computed(() => {
      return map(tableData.value, item => {
        const type = get(item.expireSetting, 'type')
        let exp = ''
        switch (type) {
        case 'forever':
          exp = '無期限'
          break
        case 'afterReceive':
          exp = `領取後${get(item.expireSetting, 'afterReceiveDays')}天`
          break
        case 'endOfPeriod':
          exp = '領取當月最後一天'
          break
        default:
          exp = dateFormat(get(item.expireSetting, 'fixedDate'), 'YYYY/MM/DD')
        }
        return {
          ...item,
          exp,
          totalAmount: get(item, 'totalAmount') || '無上限',
        }
      })
    })
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        name: (search.name === '') ? undefined : search.name,
      }
      await Promise.allSettled([
        fetchData(GetPointCard, payload),
        fetchDataCount(GetPointCardCount, payload),
      ])
    }
    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    const deletePointCard = async () => {
      try {
        await DeletePointCard({
          shopId: shopId.value,
          id: get(selectRow, 'value.id'),
        })
        await refresh()
        window.$message.success(notifyMessage.deleteSuccess)
      } catch (error) {
        console.log(error)
        window.$message.error(error)
      }
    }
    const imgSource = (image) => {
      return imgSrc(90, image || null)
    }
    onMounted(async () => {
      if (get(route.query, 'name')) {
        search.name = get(route.query, 'name')
      }
      await refresh()
    })
    onActivated(async () => {
      if (get(route.query, 'name')) {
        search.name = get(route.query, 'name')
      }
      await refresh()
    })
    return {
      tableData,
      tableDataCount,
      tableOptions,
      loading,
      search,
      deletePointCard,
      selectRow,
      imgSource,
      dateFormat,
      deleteDialog,
      ThumbnailDefault,
      refresh,
      displayData,
    }
  },
})
</script>

<style scoped lang="scss">
.break-word {
  word-break: break-word
}
</style>
