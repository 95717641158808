import { admin2 } from './instance'
// CONFIG
export const GetPointCardConfig = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/pointCardConfig`,
  })
}
export const UpdatePointCardConfig = async ({
  shopId,
  displayName,
  expireNotifyDays,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/pointCardConfig`,
    data: {
      displayName,
      expireNotifyDays,
    },
  })
}
// POINTCARD
export const GetPointCard = async ({
  shopId,
  name,
  start,
  limit,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/pointCard`,
    params: {
      name,
      start,
      limit,
    },
  })
}
export const GetPointCardCount = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/pointCard/count`,
  })
}

export const FindPointCard = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/pointCard/${id}`,
  })
}
export const CreatePointCard = async ({
  shopId,
  name,
  totalAmount,
  expireSetting,
  note,
  order,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/pointCard`,
    data: {
      name,
      totalAmount,
      expireSetting,
      note,
      order,
    },
  })
}
export const UpdatePointCard = async ({
  shopId,
  id,
  name,
  order,
  totalAmount,
  expireSetting,
  note,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/pointCard/${id}`,
    data: {
      name,
      order,
      totalAmount,
      expireSetting,
      note,
    },
  })
}
export const DeletePointCard = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'DELETE',
    url: `/${shopId}/pointCard/${id}`,
  })
}
// RECORD
export const GetPointCardRecord = async ({
  shopId,
  MemberId,
  code,
  name,
  status,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/pointCardRecord`,
    params: {
      MemberId,
      code,
      name,
      status,
    },
  })
}
export const GetPointCardRecordCount = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/pointCardRecord/count`,
  })
}
export const FindPointCardRecord = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/pointCardRecord/${id}`,
  })
}
export const CreatePointCardRecord = async ({
  shopId,
  pointCardId,
  memberId,
  price,
  note,
  multipleAmount,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/pointCardRecord`,
    data: {
      pointCardId,
      memberId,
      price,
      note,
      multipleAmount,
    },
  })
}
export const VoidPointCardRecord = async ({
  shopId,
  pointCardRecordId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/pointCardRecord/adminVoid`,
    data: {
      pointCardRecordId,
    },
  })
}
export const UsePointCardRecord = async ({
  shopId,
  pointCardRecordId,
  amount,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/pointCardRecord/adminUse`,
    data: {
      pointCardRecordId,
      amount,
    },
  })
}
export const AddPointCardRecord = async ({
  shopId,
  pointCardRecordId,
  amount,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/pointCardRecord/adminAdd`,
    data: {
      pointCardRecordId,
      amount,
    },
  })
}
